import { PublicAsset } from '../../../asset/asset.entity';
import { AssetDto } from '../../../asset/models/asset.dto';
import { BriaService } from '../../../integrations/bria/bria.service';
import { DataSourceGenerateDto } from '../../dtos/data-source-generate.dto';
import { QueryParams, QueryResult } from '../../models';
import { Connector } from '../connector';
import { ConnectorConfigBria } from './models';

export class ConnectorBria extends Connector {
	protected readonly config: ConnectorConfigBria;
	constructor(config: ConnectorConfigBria) {
		super(config);
	}

	public async query(params: QueryParams): Promise<QueryResult> {
		throw new Error('Querying is not available from ConnectorBria.');
	}

	public async generate(params: DataSourceGenerateDto): Promise<Partial<AssetDto>[]> {
		console.log('ConnectorBria.generate', params);
		let model = 'base';
		let version = '2.3';
		if (params.model) {
			// Check if any model starts with 'base', 'fast' or 'hd'.  If so, parse the model and version from the model string.
			const modelParts = params.model.split('-');
			if (modelParts.length === 2 && ['base', 'fast', 'hd'].includes(modelParts[0])) {
				model = modelParts[0];
				version = modelParts[1];
			} else {
				model = 'tailored';

				// Parse a url like https://engine.prod.bria-api.com/v1/text-to-image/tailored/babf8f22-e04d-4394-b57e-477746e8f7f6_4353_711 to get the model id.
				if (params.model.includes('https://')) {
					version = params.model.split('/').pop();
				} else {
					// Assume they put the id in the model field.
					version = params.model;
				}
			}
		}

		// Set Defaults

		console.log('ConnectorBria.generate', model, version);

		return await BriaService.generate(model, version, params);
	}

	public getSupportedInputs(): string[] {
		return [
			'model',
			'prompt',
			'aspect_ratio',
			'num_results',
			'medium',
		];
	}

	public getAspectRatios(): string[] {
		return ['3:2', '2:3', '1:1', '3:4', '4:3', '5:4', '9:16', '16:9'];
	}

	public getClient(): any {
		throw new Error('Client is not available from ConnectorBria.');
	}

	public destroy(): any {}

	public async isValid(): Promise<boolean> {
		return true;
	}

	public async write(): Promise<any> {
		throw new Error('Writing is not available from ConnectorBria.');
	}

	public async delete(): Promise<any> {
		throw new Error('Deleting is not available from ConnectorBria.');
	}

	public async getFromSource() {
		throw new Error('Getting from source is not available from ConnectorPexels.');
	}
}
