import { AssetDto } from '../../asset/models/asset.dto';
import { DataSourceGenerateDto } from '../dtos/data-source-generate.dto';
import { ConnectorConfig, ItemResult, QueryParams, QueryResult } from '../models';
import { Cache } from '@nestjs/cache-manager';

export abstract class Connector {
	public writable: boolean = false;
	protected readonly config: ConnectorConfig;
	protected cacheManager: Cache;
	constructor(config: ConnectorConfig) {
		this.config = config;
	}

	public setCacheManager(cacheManager: Cache): void {
		this.cacheManager = cacheManager;
	}

	public abstract query(params: QueryParams): Promise<QueryResult>;
	public abstract getClient(): any;
	public abstract destroy(): any;
	public abstract isValid(): Promise<boolean | Error>;
	public abstract write(dataSourceId: string, data: ItemResult[]): Promise<ItemResult[]>;
	public abstract delete(dataSourceId: string, identifiers: string[]): Promise<any>;
	public abstract getFromSource(parameters?: any): Promise<any>;

	public generate(params: DataSourceGenerateDto): Promise<Partial<AssetDto>[]> {
		throw new Error('Generating is not available from Connector.');
	};
}
