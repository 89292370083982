import { IsBoolean, IsOptional, IsString } from 'class-validator';
import { ConnectorConfig } from '../../../models';

export class ConnectorConfigBria extends ConnectorConfig {
	/* It's possible that someone copied a url into this so always parse to get the id. */
	@IsString()
	target: string;

	@IsBoolean()
	useBriaIframe?: boolean = false;

	@IsOptional()
	models?: { name: string; value: string; }[] = [
		{ name: 'Base 2.3', value: 'base-2.3' },
		{ name: 'Fast 2.3', value: 'fast-2.4' },
		{ name: 'HD 2.2', value: 'hd-2.2' },
	];
}
