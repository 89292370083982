import { Field, FieldGroup, FieldReCaptcha, FieldResult, FieldSelect, FieldType, Validators } from '../../_core/fraud-prevention/modules/forms/models';
import { DataSourceConnectorType, ItemDef, ItemFormat, ItemResult } from '../models';
import { ConnectorBria } from './bria/bria.connector';

export class ConnectorUtils {

	public static getConnectorClass(connectorType: string) {
		switch(connectorType) {
			case DataSourceConnectorType.Bria:
				return ConnectorBria;
			default:
				throw new Error(`Connector type ${connectorType} not found.`);
		}
	}

	public static itemResultToFieldResult(itemResult: ItemResult): FieldResult[] {
		return Object
			.entries(itemResult)
			.map(([key, value]) => {
				if(typeof value === 'object') {
					return {
						slug: key,
						value: this.itemResultToFieldResult(value as ItemResult)
					};
				}
				return {
					slug: key,
					value
				};
			});
	}

	public static itemDefsToFields(itemDefs: ItemDef[]): Field[] {
		return itemDefs.map(itemDef => this.itemDefToField(itemDef));
	}

	public static itemDefToField(itemDef: ItemDef): Field {
		const keys = itemDef.path.split('.');

		let field: Partial<Field> = {
			slug: keys[keys.length - 1],
			displayName: itemDef.displayName,
			type: FieldType.Text,
			validators: this.getValidatorsFromFormat(itemDef.format)
		};

		if(keys.length > 1) {
			let group: any = field;
			for(let i = keys.length - 2; i >= 0; i--) {
				group = {
					slug: keys[i],
					type: FieldType.Group,
					fields: [group],
					validators: {
						required: true
					}
				};
			}
			return group as FieldGroup;
		} else {
			return field as Field;
		}
	}

	public static getValidatorsFromFormat(format: ItemFormat): Validators {
		let validators: Validators = {
			required: true
		};
		switch(format) {
			case ItemFormat.Email:
				return {
					...validators,
					email: true
				};
			case ItemFormat.Number:
				return {
					...validators,
					number: true
				};
			case ItemFormat.Phone:
				return {
					...validators,
					phone: true
				};
			default:
				return {
					...validators,
					string: true
				};
		}
	}
}
