import { Injectable } from '@nestjs/common';
import { ImageActionBackgroundRemovalDto, ImageActionDto, ImageActionReplaceBackgroundDto, ImageActionTextToImageDto } from '../../asset/models/image-action.dto';
import { AssetDto } from '../../asset/models/asset.dto';
import { DataSourceGenerateDto } from '../../data-source/dtos/data-source-generate.dto';

@Injectable()
export class BriaService {
	constructor() {}

	public static async generate(modelName: string, modelVersion: string, params: DataSourceGenerateDto): Promise<Partial<AssetDto>[]> {
		// let endpoint = `/bria/generate-image`;
		// let key = process.env.TASKER_ENGINE_API_KEY;

		// const config: any = {
		// 	method: 'POST',
		// 	headers: {
		// 		'Content-Type': 'application/json',
		// 		'Authorization': `Bearer ${key}`
		// 	},
		// 	body: JSON.stringify({
		// 		prompt: params.prompt,
		// 		modelName,
		// 		modelVersion
		// 	})
		// };

		// console.log('URL', `${process.env.TASKER_ENGINE_URL}${endpoint}`);
		// console.log('Config', config);

		// const resp = await fetch(
		// 	`${process.env.TASKER_ENGINE_URL}${endpoint}`,
		// 	config
		// ).catch(err => {
		// 	console.error('Error calling endpoint', err);
		// 	throw err;
		// });

		// if (resp.status !== 201) {
		// 	console.log('Error calling endpoint', endpoint, resp.statusText, resp, config);
		// 	throw new Error(resp.statusText);
		// }

		// let response = await resp.json();
		// return (response?.result || [])?.map((item: { urls: string; seed: number; }) => ({
		// 	assetPath: `${process.env.TASKER_ENGINE_URL}/asset/px/path/${item.urls}`,
		// 	externalIds: {
		// 		bria_seed: item.seed
		// 	}
		// })) as Partial<AssetDto>[];

		const data = await this.callEndpoint(
			`text-to-image/${modelName}/${modelVersion}`,
			'POST',
			{
				prompt: params.prompt,
				asset: undefined,
				sync: true,
				prompt_enhancement: true,
				aspect_ratio: params.aspect_ratio || '3:2',
			}
		);

		return (data?.result || [])?.map((item: { urls: string; seed: number; }) => ({
			assetPath: item.urls,
			externalIds: {
				bria_seed: item.seed
			}
		})) as Partial<AssetDto>[];
	}

	public static async textToImage(actionConfig: ImageActionDto<ImageActionTextToImageDto>) {
		console.log('text to image:', actionConfig.config?.prompt);

		const data = await this.callEndpoint(
			`text-to-image/hd/2.2`,
			'POST',
			{
				...actionConfig.config,
				asset: undefined,
				sync: true,
				prompt_enhancement: true
			}
		);

		return (data?.result || [])?.map((item: { urls: string; seed: number; }) => ({
			assetPath: item.urls,
			externalIds: {
				...(actionConfig.config.asset?.externalIds || {}),
				bria_seed: item.seed
			}
		})) as Partial<AssetDto>[];
	}

	public static async removeBackground(actionConfig: ImageActionDto<ImageActionBackgroundRemovalDto>) {
		const formData = {
			image_url: actionConfig.config.asset.assetPath
		};

		const data = await this.callEndpoint(
			'background/remove',
			'POST',
			new URLSearchParams(formData).toString(),
			{ 'Content-Type': 'application/x-www-form-urlencoded' }
		);

		return {
			assetPath: data.result_url
		} as Partial<AssetDto>;
	}

	public static async blurBackground(actionConfig: ImageActionDto<ImageActionBackgroundRemovalDto>) {
		const query = new URLSearchParams({
			// scale: '4',
			// desired_resolution: 'original'
		});
		const queryString = query.toString();

		const visualId = await this.getVisualId(actionConfig.config.asset);
		console.log('Blurring background with visual id', visualId, 'and query', queryString);

		const data = await this.callEndpoint(`${visualId}/background/blur?${queryString}`, 'GET')

		return {
			assetPath: data.image_res,
			externalIds: {
				...(actionConfig.config.asset?.externalIds || {}),
				bria_visual_id: visualId
			}
		} as Partial<AssetDto>;
	}

	public static async replaceBackground(actionConfig: ImageActionDto<ImageActionReplaceBackgroundDto>) {

		const visualId = await this.getVisualId(actionConfig.config.asset);
		console.log('Replacing background with visual id', visualId);

		const data = await this.callEndpoint(
			`${visualId}/background/replace`,
			'POST',
			{
				...actionConfig.config,
				asset: undefined,
				sync: true
			}
		);

		return (data?.result || [])?.map((item: [string, number, string]) => ({
			assetPath: item[0],
			externalIds: {
				...(actionConfig.config.asset?.externalIds || {}),
				bria_visual_id: visualId
			}
		})) as Partial<AssetDto>[];
	}

	public static async callEndpoint(endpoint: string, method: string, body?: any, headers?: any) {
		const config = {
			method,
			headers: {
				'Content-Type': 'application/json',
				api_token: process.env.BRIA_API_KEY,
				...(headers || {})
			}
		};

		if (body) {
			if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
				config['body'] = body;
			} else {
				config['body'] = JSON.stringify(body);
			}
		}

		console.log('Endpoint', endpoint);
		console.log('Config', config);

		const resp = await fetch(
			`https://engine.prod.bria-api.com/v1/${endpoint}`,
			config
		).catch(err => {
			console.error('Error calling endpoint', err);
			throw err;
		});

		if (resp.status !== 200) {
			console.log('Error calling endpoint', endpoint, resp.statusText, config);
			throw new Error(resp.statusText);
		}

		return resp.json();
	}

	public static async getVisualId(asset: Partial<AssetDto>): Promise<string> {
		if (asset.externalIds?.bria_visual_id) {
			return asset.externalIds.bria_visual_id;
		}

		console.log('Creating visual id for asset', asset.assetPath);
		const resp = await fetch(
			`https://engine.prod.bria-api.com/v1/register`,
			{
			  method: 'POST',
			  headers: {
				'Content-Type': 'application/json',
				api_token: process.env.BRIA_API_KEY
			  },
			  body: JSON.stringify({
				image_url: asset.assetPath
			  })
			}
		  );

		  const data = await resp.json();
		  console.log('Got visual id', data.visual_id);
		  return data.visual_id;
	}



}
